.OrganizationModalInput {
    border-radius: @border-radius-base-x2;

    div {
        border-radius: @border-radius-base-x2 !important;
        display: flex;
    }
}

.EditOrganizationButton {
    margin: 0 !important;
    padding: 0 !important;
    color: #000000d9 !important;

    :global(.ant-btn) {
        box-shadow: none !important;
        padding: 0 !important;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";