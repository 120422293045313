.EditOrganizationButton {
    margin: 0 !important;
    padding: 0 !important;
    color: #000000d9 !important;

    :global(.ant-btn) {
        box-shadow: none !important;
        padding: 0 !important;
    }
}

.DeleteOrganizationPopoverButton {
    margin: 0 !important;
    padding: 0 !important;
    color: @ccxRed;

    &:hover {
        background: transparent !important;
        color: @ccxRed !important;
        box-shadow: none !important;
    }

    :global(.ant-btn) {
        box-shadow: none !important;
        padding: 0 !important;
    }
}

@hack: true; @import "/src/src/antd-default-theme.less";